import React from 'react'

export const Custom6 = () => (
    <div className="wrapper">
        <div className="product-info">
            <div>
                <span>ДВИГАТЕЛЬ</span>
                <ul>
                    <li>
                        Дизельный двигатель серии SCANIA
                    </li>
                    <li>
                        4 цикла, с водяным охлаждением, двигатель с турбонаддувом
                    </li>
                    <li>
                        Предкамерный впрыск
                    </li>
                    <li>
                        Электронная система регулятора
                    </li>
                    <li>
                        24-вольтовый стартер и зарядное устройство-генератор
                    </li>
                    <li>
                        Сменный воздушный, топливный и масляный фильтры
                    </li>
                    <li>
                        Радиатор панельного типа
                    </li>
                    <li>
                        Гибкая топливная труба
                    </li>
                    <li>
                        Клапан слива масла и расширительная труба
                    </li>
                    <li>
                        Промышленный глушитель, выхлопная спираль или компенсатор
                    </li>
                    <li>
                        Батарея, не требующая обслуживания
                    </li>
                    <li>
                        Водонагреватель блока цилиндров (в автоматических моделях)
                    </li>
                    <li>
                        Инструкции по эксплуатации
                    </li>
                </ul>
            </div>

            <div>
                <span>ГЕНЕРАТОР ПЕРЕМЕННОГО ТОКА</span>
                <ul>
                    <li>
                        Бесщеточный, с одинарным гибким диском 4-полюсный
                    </li>
                    <li>
                        генератор переменного тока при гармонических колебаниях
                    </li>
                    <li>
                        Класс изоляции H-типа
                    </li>
                    <li>
                        Класс защиты IP 21-23
                    </li>
                    <li>
                        С самовозбуждением
                    </li>
                    <li>
                        Электронный автоматический регулятор напряжения
                    </li>
                    <li>
                        Статор 2/3 ступени при гармонических колебаниях
                    </li>
                </ul>
            </div>

            <div>
                <span>КОЖУХ</span>
                <ul>
                    <li>
                        Шумозащитный кожух модульного типа
                    </li>
                    <li>
                        Установка кожуха выполняется с помощью винта и гайки
                    </li>
                    <li>
                        Запираемые с двух сторон двери
                    </li>
                    <li>
                        Кнопка аварийной остановки
                    </li>
                </ul>
            </div>

            <div>
                <span>ДОПОЛНИТЕЛЬНОЕ ОБОРУДОВАНИЕ</span>
                <ul>
                    <li>
                        Зарядный амперметр
                    </li>
                    <li>
                        Автомат защиты в литом корпусе (в автоматических моделях)
                    </li>
                    <li>
                        Глушитель для жилых зон / критического типа
                    </li>
                    <li>
                        Шумозащитный кожух
                    </li>
                    <li>
                        Мобильный прицеп
                    </li>
                    <li>
                        Синхронизированная панель управления
                    </li>
                    <li>
                        3-х полюсный / 4-х полюсный автоматический ввод резерва (ABP)
                    </li>
                    <li>
                        Топливный и масляный подогреватель
                    </li>
                    <li>
                        Подогреватель генератора переменного тока
                    </li>
                    <li>
                        Система автоматической дозаправки топлива
                    </li>
                    <li>
                        Фильтр-сепаратор топлива-воды
                    </li>
                </ul>
            </div>

            <div>
                <span>АВТОМАТИЧЕСКАЯ ПАНЕЛЬ УПРАВЛЕНИЯ</span>
                <ul>
                    <li>
                        ЖК-дисплей
                    </li>
                    <li>
                        Необходимое оборудование и материалы
                    </li>
                    <li>
                        Зарядное устройство
                    </li>
                    <li>
                        Порт USB и выход RS-485
                    </li>
                </ul>
            </div>

            <div className="table-responsive">
                <table>
                    <thead>
                        <tr className="table-head">
                            <th className="description">ДИЗЕЛЬ-ГЕНЕРАТОРНАЯ УСТАНОВКА</th>
                            <th>QUANTUM S 0700</th>
                            <th>QUANTUM S 0770</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="description">МОЩНОСТЬ РЕЗЕРВНАЯ КВА</td>
                            <td>700</td>
                            <td>770</td>
                        </tr>
                        <tr>
                            <td className="description">МОЩНОСТЬ РЕЗЕРВНАЯ КВТ</td>
                            <td>560</td>
                            <td>616</td>
                        </tr>
                        <tr>
                            <td className="description">МОЩНОСТЬ ОСНОВНАЯ КВА</td>
                            <td>636</td>
                            <td>700</td>
                        </tr>
                        <tr>
                            <td className="description">МОЩНОСТЬ ОСНОВНАЯ КВТ</td>
                            <td>509</td>
                            <td>560</td>
                        </tr>
                        <tr>
                            <td className="description">РАЗМЕРЫ С КОЖУХОМ ММ</td>
                            <td>N/A</td>
                            <td>N/A</td>
                        </tr>
                        <tr>
                            <td className="description">ВЕС С КОЖУХЕ КГ</td>
                            <td>N/A</td>
                            <td>N/A</td>
                        </tr>
                        <tr>
                            <td className="description">ГАБАРИТЫ В ОТКРЫТОМ ИСПОЛНЕНИИ</td>
                            <td>N/A</td>
                            <td>N/A</td>
                        </tr>
                        <tr>
                            <td className="description">ВЕС КГ</td>
                            <td>N/A</td>
                            <td>N/A</td>
                        </tr>
                        <tr>
                            <td className="description">МАРКА</td>
                            <td>VOLVO</td>
                            <td>VOLVO</td>
                        </tr>
                        <tr>
                            <td className="description">ТИП</td>
                            <td>DC16 093A 02-54</td>
                            <td>DC16 072A 02-13</td>
                        </tr>
                        <tr>
                            <td className="description">МОЩНОСТЬ РЕЗЕРВНАЯ КВА</td>
                            <td>614</td>
                            <td>680</td>
                        </tr>
                        <tr>
                            <td className="description">МОЩНОСТЬ ОСНОВНАЯ КВТ</td>
                            <td>558,1818182</td>
                            <td>621</td>
                        </tr>
                        <tr>
                            <td className="description">СКОРОСТЬ ВРАЩЕНИЯ ОБ/МИН</td>
                            <td>1500</td>
                            <td>1500</td>
                        </tr>
                        <tr>
                            <td className="description">ЦИКЛ</td>
                            <td>4</td>
                            <td>4</td>
                        </tr>
                        <tr>
                            <td className="description">ЛИТРАЖ</td>
                            <td>16,4</td>
                            <td>16,4</td>
                        </tr>
                        <tr>
                            <td className="description">КОЛИЧЕСТВО ЦИЛИНДРОВ</td>
                            <td>8 V</td>
                            <td>8 V</td>
                        </tr>
                        <tr>
                            <td className="description">ВНУТРЕННИЙ ДИАМЕТР И ДЛИНА ХОДА ММ</td>
                            <td>130 x 154</td>
                            <td>130 x 154</td>
                        </tr>
                        <tr>
                            <td className="description">КОЭФФИЦИЕНТ СЖАТИЯ</td>
                            <td>16,7 : 1</td>
                            <td>16,7 : 1</td>
                        </tr>
                        <tr>
                            <td className="description">ТИП РЕГУЛЯТОРА</td>
                            <td>ЭЛЕКТРОНИКА</td>
                            <td>ЭЛЕКТРОНИКА</td>
                        </tr>
                        <tr>
                            <td className="description">ИНДУКЦИОННЫЙ</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td className="description">СИСТЕМА СГОРАНИЯ</td>
                            <td>DIRECT</td>
                            <td>DIRECT</td>
                        </tr>
                        <tr>
                            <td className="description">СИСТЕМА ОХЛАЖДЕНИЯ</td>
                            <td>ВОДЯНОЕ</td>
                            <td>ВОДЯНОЕ</td>
                        </tr>
                        <tr>
                            <td className="description">РАСХОД ТОПЛИВА 100% МОЩНОСТИ</td>
                            <td>129</td>
                            <td>142</td>
                        </tr>
                        <tr>
                            <td className="description">РАСХОД ТОПЛИВА 75% МОЩНОСТИ</td>
                            <td>93</td>
                            <td>105</td>
                        </tr>
                        <tr>
                            <td className="description">РАСХОД ТОПЛИВА 50% МОЩНОСТИ</td>
                            <td>63</td>
                            <td>71</td>
                        </tr>
                        <tr>
                            <td className="description">СУММАРНАЯ ЕМКОСТЬ СИСТЕМЫ СМАЗКИ Л</td>
                            <td>48</td>
                            <td>45</td>
                        </tr>
                        <tr>
                            <td className="description">СУММАРНАЯ ЕМКОСТЬ СИСТЕМЫ ОХЛАЖДЕНИЯ Л</td>
                            <td>68</td>
                            <td>68</td>
                        </tr>
                        <tr>
                            <td className="description">ЕМКОСТЬ ТОПЛИВНОГО БАКА Л</td>
                            <td>N/A</td>
                            <td>N/A</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
)