import { Custom1 } from "./custom1";
import { Custom2 } from "./custom2";
import { Custom3 } from "./custom3";
import { Custom4 } from "./custom4";
import { Custom5 } from "./custom5";
import { Custom6 } from "./custom6";
import { Custom7 } from "./custom7";
import { Custom8 } from "./custom8";
import { Custom9 } from "./custom9";
import { Custom10 } from "./custom10";
import { Custom11 } from "./custom11";
import { Custom12 } from "./custom12";
import { Custom13 } from "./custom13";
import { Custom14 } from "./custom14";
import { Custom15 } from "./custom15";
import { Custom16 } from "./custom16";
import { Custom17 } from "./custom17";
import { Custom18 } from "./custom18";
import { Custom19 } from "./custom19";
import { Custom20 } from "./custom20";
import { Custom21 } from "./custom21";
import { Custom22 } from "./custom22";
import { Custom23 } from "./custom23";
import { Custom24 } from "./custom24";
import { Custom25 } from "./custom25";
import { Custom26 } from "./custom26";
import { Custom27 } from "./custom27";
import { Custom28 } from "./custom28";
import { Custom29 } from "./custom29";
import { Custom30 } from "./custom30";
import { Custom31 } from "./custom31";
import { Custom32 } from "./custom32";
import { Custom33 } from "./custom33";
import { Custom34 } from "./custom34";
import { Custom35 } from "./custom35";
import { Custom36 } from "./custom36";
import { Custom37 } from "./custom37";
import { Custom38 } from "./custom38";
import { Custom39 } from "./custom39";
import { Custom40 } from "./custom40";

export default {
    1: Custom1,
    2: Custom2,
    3: Custom3,
    4: Custom4,
    5: Custom5,
    6: Custom6,
    7: Custom7,
    8: Custom8,
    9: Custom9,
    10: Custom10,
    11: Custom11,
    12: Custom12,
    13: Custom13,
    14: Custom14,
    15: Custom15,
    16: Custom16,
    17: Custom17,
    18: Custom18,
    19: Custom19,
    20: Custom20,
    21: Custom21,
    22: Custom22,
    23: Custom23,
    24: Custom24,
    25: Custom25,
    26: Custom26,
    27: Custom27,
    28: Custom28,
    29: Custom29,
    30: Custom30,
    31: Custom31,
    32: Custom32,
    33: Custom33,
    34: Custom34,
    35: Custom35,
    36: Custom36,
    37: Custom37,
    38: Custom38,
    39: Custom39,
    40: Custom40
}