import React from 'react'
import Button from '../Button'
import './style.css'

export const FeedBack = () => {

    return (
        <form className='feed-back' method='post' action='smtp.php'>
            <label>ОБРАТНАЯ СВЯЗЬ</label>
            <input
                name='fio'
                type='text'
                placeholder='Фамилия Имя Отчество'
            />
            <input
                name='phone'
                type='text'
                placeholder='+998 -- --- -- --'
            />
            <input
                name='mail'
                type='text'
                placeholder='you@example.com'
            />
            <input
                name='content'
                type='textarea'
                placeholder='Коментарий'
            />
            <Button className='btn-outline-white' type='submit'>Отправить</Button>
        </form>
    )
}