

 export default  [
    {
        id: 1,
        name:"ГЛАВНАЯ",
        url: "#home"
    },
    {
        id: 2,
        name:"О НАС",
        url: "#about"
    },
    {
        id: 3,
        name: "МЕДОБОРУДОВАНИЕ",
        url: "#medicine"
    },
    {
        id: 4,
        name:"ЭЛЕКТРООБОРУДОВАНИЕ",
        url: "#electric"
    },
    {
        id: 5,
        name:"КОНТАКТЫ",
        url: "#contacts"
    }
]